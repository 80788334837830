import { DownloadIcon, Flex, Image, Text } from "@fluentui/react-northstar";
import React, { memo } from "react";
import { useContactImageLoader } from "./ContactImageLoader.logic";
import { UseFormSetValue } from "react-hook-form";
import "./ContactImageLoader.scss";
import { getInitials } from "front";
import { IContact } from "../../interfaces/IContact/IContact";
import { NO_INPUT_VALUE } from "../../const/const";
import { addBase64Prefix } from "../../utils/addBase64Prefix";
import { IContactDialogRef } from "../../interfaces/IContactDialogRef/IContactDialogRef";

const ContactImageLoader = memo(
  (props: { setValue: UseFormSetValue<IContact>; readOnly: boolean; defaultImage: string | undefined; name: string | undefined; onRef?: (ref: IContactDialogRef) => void }) => {
    const logic = useContactImageLoader(props);

    return (
      <Flex hAlign="center" vAlign="center" style={{ position: "relative" }} className="contactImageLoader-container">
        <Flex hAlign="center" vAlign="center" column className="contactImageLoader-btn-container">
          {logic.imgSrc || props.defaultImage ? (
            <div className="contactImageLoader-loaded-image-container">
              <Image src={logic.imgSrc ?? props.defaultImage as string} circular className="contact-card-picture" data-read-only={props.readOnly} />

              <div className="contactImageLoader-hover" data-read-only={props.readOnly} onClick={!props.readOnly ? logic.openFiles : undefined}>
                <DownloadIcon size="large" />
              </div>
            </div>
          ) : (
            <Flex hAlign="center" vAlign="center" className="contact-card-no-picture" data-read-only={props.readOnly} onClick={!props.readOnly ? logic.openFiles : undefined}>
              <Text content={getInitials(props.name ?? NO_INPUT_VALUE)} size="larger" title={props.name} />
            </Flex>
          )}

          <input style={{ visibility: "hidden", position: "absolute" }} type="file" accept="image/*" onChange={logic.handleInput} ref={logic.inpRef} />
        </Flex>
      </Flex>
    );
  }
);

export default ContactImageLoader;
