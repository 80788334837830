import { CONTACT_PROPERTIES_MAX_LENGTH, CONTACT_URL_PROPERTIES_MAX_LENGTH } from "../const/const";
import { ISchemaError } from "../interfaces/ISchemaError/ISchemaError";
import { z } from "zod";

export const internetSchema = z.object({
  email: z
    .string({ invalid_type_error: ISchemaError.EMAIL_INVALID_TYPE })
    .email({ message: ISchemaError.EMAIL_INVALID_FORMAT })
    .max(CONTACT_PROPERTIES_MAX_LENGTH, { message: ISchemaError.EMAIL_TOO_LONG })
    .or(z.literal("").optional())
    .default(""),

  website: z.string({ invalid_type_error: ISchemaError.WEBSITE_INVALID_TYPE }).max(CONTACT_URL_PROPERTIES_MAX_LENGTH, { message: ISchemaError.WEBSITE_TOO_LONG }).or(z.literal("").optional()).default(""),
  facebook: z.string({ invalid_type_error: ISchemaError.FACEBOOK_INVALID_TYPE }).max(CONTACT_URL_PROPERTIES_MAX_LENGTH, { message: ISchemaError.FACEBOOK_TOO_LONG }).default(""),
  linkedin: z.string({ invalid_type_error: ISchemaError.LINKEDIN_INVALID_TYPE }).max(CONTACT_URL_PROPERTIES_MAX_LENGTH, { message: ISchemaError.LINKEDIN_TOO_LONG }).default(""),
  twitter: z.string({ invalid_type_error: ISchemaError.X_INVALID_TYPE }).max(CONTACT_URL_PROPERTIES_MAX_LENGTH, { message: ISchemaError.X_TOO_LONG }).or(z.literal("").optional()).default(""),
});
