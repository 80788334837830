import { Button, CallIcon, EmailIcon, Flex, Image, MenuButton, MoreIcon, Text } from "@fluentui/react-northstar";
import { IContact } from "../../interfaces/IContact/IContact";
import { FC, memo } from "react";
import { useUserCard } from "./UserCard.logic";
import "./UserCard.scss";
import React from "react";
import { getInitials, shouldTruncate } from "front";
import { NO_INPUT_VALUE } from "../../const/const";
import { addBase64Prefix } from "../../utils/addBase64Prefix";

const UserCard: FC<IContact & { idx: number; removeFromGroup?: (id: string) => void }> = memo((props) => {
  const logic = useUserCard(props);

  return (
    <>
      <div className="contact-card-container" style={{ animationDelay: `calc(${props.idx} * 0.075s)` }}>
        {props.isDisabled && (
          <Flex className="contact-card-sail">
            <Flex className="contact-card-sail-content" vAlign="center" hAlign="center" column gap="gap.small">
              <Text align="center" content={logic.t("PremiumMessageCard", { license: logic.isPremium ? "Platinum" : "Premium" })} size="medium" />
              <Button onClick={logic.openPremium} content={logic.t("PremiumButtonCard", { license: logic.isPremium ? "Platinum" : "Premium" })} size="medium" primary />
            </Flex>
          </Flex>
        )}
        <Flex vAlign="center" hAlign="center" className="contact-card-top" onClick={logic.seeContact}>
          {props.picture ? (
            <Image src={props.picture} circular className="contact-card-picture" />
          ) : (
            <Flex hAlign="center" vAlign="center" className="contact-card-no-picture">
              <Text content={getInitials(props.name)} size="larger" title={props.name} />
            </Flex>
          )}
        </Flex>
        <Flex vAlign={logic.isOnMobile ? "center" : "start"} hAlign={logic.isOnMobile ? "center" : "start"} column className="contact-card-bottom" onClick={logic.seeContact}>
          <Text content={shouldTruncate(props.name, 16) ? `${props.name.substring(0, 16)}...` : props.name} weight="bold" style={{ fontSize: "0.925rem", lineHeight: "1.25" }} title={props.name} />
          <Text content={props.jobTitle ? (shouldTruncate(props.jobTitle, 20) ? `${props.jobTitle.substring(0, 16)}...` : props.jobTitle) : NO_INPUT_VALUE} />
          <Flex hAlign="start" vAlign="center" style={{ padding: ".25rem 0", marginTop: ".5rem" }}>
            <CallIcon outline size="small" style={{ marginRight: ".3rem" }} />
            <Text content={props.phones?.mobile ? props.phones?.mobile : NO_INPUT_VALUE} size="small" weight="light" />
          </Flex>
          <Flex hAlign="start" vAlign="center" style={{ padding: ".25rem 0" }}>
            <EmailIcon outline size="small" style={{ marginRight: ".3rem" }} />
            <Text
              content={props.internet?.email ? (shouldTruncate(props.internet?.email, 19) ? `${props.internet?.email.substring(0, 19)}...` : props.internet?.email) : NO_INPUT_VALUE}
              size="small"
              weight="light"
              title={props.internet?.email ?? ""}
            />
          </Flex>
        </Flex>
        <MenuButton title="More" trigger={<Button icon={<MoreIcon />} iconOnly text />} menu={logic.menu} on="click" className="contact-card-menu-btn" />
      </div>
    </>
  );
});

export default UserCard;
