import { Avatar, Button, Card, EmailIcon, Flex, FlexItem, Image, Text } from "@fluentui/react-northstar";
import React from "react";
import { HomePro } from "@witivio_teamspro/home-pro-sdk";
import { IContact } from "../../interfaces/IContact/IContact";
import { getContact } from "../../apis/actions/contactAction";
import { translations } from "../../translations";
import { useTranslate } from "front";

const baseAxiosUrl = process.env.REACT_APP_APIBaseUrl ?? "";

export const CardPersonBasic = (props: { person: IContact; tenantId: string }) => {
  const t = useTranslate(translations)
  
  const renderEmoji = (name: string): string => {
    if (name) {
      let arrayOnlyCharsAndNull = name.split(/[_|\W]/);
      let arrayOlyChars = arrayOnlyCharsAndNull.map((item: any) => (item !== "" ? item : false));
      let arrayFinal = arrayOlyChars.filter((item: any) => item);
      let name_final = arrayFinal.join("");
      return name_final.toUpperCase();
    } else {
      return "";
    }
  };

  const onClickViewContact = async () => {
    getContact(props.person.contactListId as string, props.person.id as string)
      .then((contact) => {
        const cb = new BroadcastChannel("contact");
        cb.onmessage = (ev: MessageEvent<string>) => {
          if (ev) {
            if (ev.data === props.person.contactListId) {
              cb.postMessage(contact);
            }
          }
        };
      })
      .catch(console.log);

    const queryStr = new URLSearchParams({
      contactListId: props.person.contactListId as string,
      contactId: props.person.id as string,
    }).toString();

    await HomePro.popup.start({
      title: props.person.name ?? "Contact details",
      width: 600,
      height: 600,
      url: `${baseAxiosUrl}/widget/detail?${queryStr}`,
    });
  };

  return (
    <FlexItem>
      <Card aria-roledescription="card avatar" className={"displayCardPersonBasic"} onClick={onClickViewContact}>
        <Card.Header fitted>
          <Flex gap="gap.small">
            {props.person.picture ? (
              <Image circular src={props.person.picture} style={{ height: "44px", borderTopRightRadius: "62.4375rem !important", borderTopLeftRadius: "62.4375rem !important", borderBottomRightRadius: "62.4375rem !important", borderBottomLeftRadius: "62.4375rem !important" }} />
            ) : (
              <Avatar size={"large"} name={renderEmoji(props.person.name as string)} />
            )}
            <Flex
              column
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              <Flex gap={"gap.small"} vAlign={"end"} style={{ marginLeft: "8px" }}>
                <Text id="nameContact" truncated content={props.person.name} weight="bold" />
                <Text id="companyContact" truncated content={props.person.company} />
              </Flex>
              <Flex hAlign="start">
                <Flex hAlign="start">
                  {props.person.internet?.email && (
                    <a href={`mailto:${props.person.internet?.email}`} onClick={(e) => e.stopPropagation()}>
                      <Button icon={<EmailIcon />} iconOnly text title={t("EmailMenuCardPerson")} />
                    </a>
                  )}
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Card.Header>
      </Card>
    </FlexItem>
  );
};
