import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IAppDispatch, ReduxStoreState } from "../../../../redux/store";
import { openModal, useMsTeamsSelector, useTranslate } from "front";
import { translations } from "../../../../translations";
import { setUserToDelete } from "../../../../redux/reducer/ContactListReducer";
import { useUsersCache } from "../../../../hooks/useQuery/useUsersCache";
import { useNavigate } from "react-router-dom";
import { queryClient } from "../../../../index";
import { QUERY_KEY_PARTICIPANTS } from "../../../../const/queryKey";
import { IContact } from "../../../../interfaces/IContact/IContact";

export const useDeleteContact = () => {
  // Services
  const { mutateDeleteContact } = useUsersCache();
  const dispatchCtx = useDispatch<IAppDispatch>();
  const t = useTranslate(translations);
  const userToDelete = useSelector((s: ReduxStoreState) => s.contactList.deleteUser);
  const navigate = useNavigate();
  const appId = useSelector((s: ReduxStoreState) => s.appConfiguration.appId);
  const { tenantId } = useMsTeamsSelector("tenantId");

  const onClose = useCallback(() => {
    dispatchCtx(openModal(undefined));
    dispatchCtx(setUserToDelete({ contact: undefined, isLonely: false }));
  }, []);

  const handleSubmit = useCallback(async () => {
    try {
      const deletedUser = { contactListId: appId, contactId: userToDelete.contact!.id, isLonely: userToDelete.isLonely };
      await mutateDeleteContact.mutateAsync(deletedUser, {
        onSuccess: () => {
          const groupId = userToDelete.contact!.groupId;
          if (!groupId) return;
          const allGroupIds = groupId.split(", ");
          allGroupIds.forEach((g) => {
            queryClient.setQueryData([QUERY_KEY_PARTICIPANTS, appId, g, tenantId], (oldData: IContact[] | undefined) => {
              if (!oldData) return [] as IContact[];
              const filteredContacts = oldData.filter((od) => od.id !== userToDelete.contact!.id);
              return filteredContacts;
            });
          });
        },
      });
      onClose();
      navigate(`/contact/${appId}`);
    } catch (error) {
      console.error(error);
    }
  }, [userToDelete]);

  return { t, onClose, handleSubmit, mutateDeleteContact };
};
