import { jsx as _jsx } from "react/jsx-runtime";
import React, { useState } from "react";
import { Alert, ClipboardCopiedToIcon, CloseIcon, ExclamationTriangleIcon, Flex } from "@fluentui/react-northstar";
import { memo, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { removeErrorMessage, useErrorSelector } from "../../redux/reducers/ErrorReducer/ErrorReducer";
import copy from "copy-to-clipboard";
export const ErrorBanner = memo(() => {
    const dispatch = useDispatch();
    const { messages } = useErrorSelector("messages");
    const [state, setState] = useState({ showPopupCopied: false });
    // Refs
    const dataRef = useRef([]);
    useEffect(() => {
        hasNewError();
    }, [messages]);
    const onCopy = (stringToCopy) => {
        copy(stringToCopy);
        setState({ showPopupCopied: true });
        setTimeout(() => {
            setState({ showPopupCopied: false });
        }, 1000);
    };
    const hasNewError = () => {
        messages.forEach((message) => {
            if (dataRef.current.includes(message))
                return;
            dataRef.current.push(message.id);
            setTimeout(() => {
                deleteError(message.id);
            }, 5000);
        });
    };
    const deleteError = (id) => {
        dataRef.current = dataRef.current.filter((item) => item !== id);
        dispatch(removeErrorMessage(id));
    };
    return (_jsx(Flex, { vAlign: "start", hAlign: "center", column: true, fill: true, style: { position: "fixed", top: 0, left: 0, zIndex: 1000000, pointerEvents: "none" }, children: messages.map((err) => (_jsx(Alert, { header: err.message, visible: true, danger: true, icon: _jsx(ExclamationTriangleIcon, {}), fitted: true, style: { width: "100%", pointerEvents: "all" }, actions: [
                { icon: _jsx(ClipboardCopiedToIcon, {}), title: "Copy", onClick: () => onCopy(JSON.stringify(err)), text: true, iconOnly: true },
                { icon: _jsx(CloseIcon, {}), title: "Close", onClick: () => deleteError(err.id), text: true, iconOnly: true },
            ] }, err.id))) }));
});
