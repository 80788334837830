import { GuidModule } from "../../modules/Guid.module";
import { teamsFxGraphClient } from "../../redux/reducers/ScopesReducer/ScopesReducer";
import { useRef } from "react";
import { PARTICIPANT_NO_ID } from "../../const/const";
const localStorageUserDataRef = "user_data_";
const searchForUsersAsync = (query) => {
    return new Promise(async (resolve) => {
        if (!teamsFxGraphClient)
            return new Error("TeamxFx graph client is undefined");
        const usersRawData = (await teamsFxGraphClient
            .api("/users")
            .filter(`startswith(displayName, '${query}')`)
            .select("id,displayName,userPrincipalName")
            .get()).value;
        const usersData = usersRawData.map((data) => data);
        await attachPicturesToUsersDataAsync(usersData);
        saveRemoteDataToLocalStorage(usersData);
        return resolve(usersData);
    });
};
const areUsersInTeam = async (userIds, teamId) => {
    if (userIds.length === 0)
        return new Array();
    if (!teamsFxGraphClient)
        return new Error("TeamxFx graph client is undefined");
    const batchUsersRequests = {
        requests: userIds.map((id) => ({
            id: id,
            method: "GET",
            url: `/teams/${teamId}/members?$filter=(microsoft.graph.aadUserConversationMember/userId eq '${id}')&$select=id`,
        })),
    };
    const usersRawData = (await teamsFxGraphClient.api("/$batch").post(JSON.stringify(batchUsersRequests))).responses;
    const validUserIds = new Array();
    usersRawData.forEach((request) => {
        const count = request.body["@odata.count"];
        if (count > 0)
            validUserIds.push(request.id);
    });
    return validUserIds;
};
const getSitesId = async (teamSiteDomain, teamSitePath) => {
    if (!teamsFxGraphClient)
        return new Error("TeamxFx graph client is undefined");
    const response = await teamsFxGraphClient.api(`/sites/${teamSiteDomain}:${teamSitePath}`).get();
    return response;
};
const getImageFromSharepoint = async (url) => {
    if (!teamsFxGraphClient)
        return new Error("TeamxFx graph client is undefined");
    const response = await teamsFxGraphClient.api(url).get();
    return response;
};
const getDriveIdSharepoint = async (url) => {
    if (!teamsFxGraphClient)
        return new Error("TeamxFx graph client is undefined");
    const response = await teamsFxGraphClient.api(url).get();
    return response;
};
const getFolderIdSharepoint = async (url) => {
    if (!teamsFxGraphClient)
        return new Error("TeamxFx graph client is undefined");
    const response = await teamsFxGraphClient.api(url).get();
    return response;
};
const postImageToSharepoint = async (url, file) => {
    if (!teamsFxGraphClient)
        return new Error("TeamxFx graph client is undefined");
    const response = await teamsFxGraphClient.api(url).put(file);
    return response;
};
const getUsersAsync = (usersIds) => {
    return new Promise(async (resolve) => {
        if (!teamsFxGraphClient)
            return new Error("TeamxFx graph client is undefined");
        const validIds = usersIds.filter((id) => GuidModule.isValidGuid(id));
        const localUsersData = new Array();
        if (!!localStorage) {
            validIds.forEach((id) => {
                const localData = localStorage.getItem(localStorageUserDataRef + id);
                if (!!localData)
                    localUsersData.push(JSON.parse(localData));
            });
            if (localUsersData.length === validIds.length) {
                resolve(localUsersData);
                return;
            }
        }
        const localUsersIds = localUsersData.map((u) => u.id);
        let usersIdsToFetch = validIds.filter((id) => !localUsersIds.includes(id));
        const usersData = new Array();
        const batchUsersRequests = new Array();
        batchUsersRequests.push({ requests: new Array() });
        let requestCount = 0;
        while (usersIdsToFetch.length > 0) {
            // 15 conditions is the limit for odata filter
            const idsToFetch = usersIdsToFetch.slice(0, 15);
            usersIdsToFetch = usersIdsToFetch.slice(15, usersIdsToFetch.length - 1);
            const filter = idsToFetch.map((id) => "id eq '" + id + "'").join(" or ");
            const request = {
                id: "request-" + requestCount,
                url: `/users?$filter=(${encodeURIComponent(filter)})&$select=id,displayName,userPrincipalName`,
                method: "GET",
            };
            let lastBatchRequest = batchUsersRequests[batchUsersRequests.length - 1];
            if (!lastBatchRequest)
                return new Error("Batch request is undefined");
            // 20 is the limit of steps in a batch request
            if (lastBatchRequest.requests.length < 20) {
                lastBatchRequest.requests.push(request);
            }
            else {
                lastBatchRequest = { requests: new Array() };
                lastBatchRequest.requests.push(request);
                batchUsersRequests.push(lastBatchRequest);
            }
            requestCount += 1;
        }
        for (const batchRequest of batchUsersRequests) {
            if (!teamsFxGraphClient)
                return new Error("TeamxFx graph client is undefined");
            const responses = (await teamsFxGraphClient.api("/$batch").post(JSON.stringify(batchRequest))).responses;
            responses.forEach((request) => {
                if (request.body.value)
                    usersData.push(...request.body.value);
            });
        }
        await attachPicturesToUsersDataAsync(usersData);
        saveRemoteDataToLocalStorage(usersData);
        usersData.push(...localUsersData);
        return resolve(usersData);
    });
};
const saveRemoteDataToLocalStorage = (usersData) => {
    if (!!localStorage)
        usersData.forEach((u) => localStorage.setItem(localStorageUserDataRef + u.id, JSON.stringify(u)));
};
const attachPicturesToUsersDataAsync = (usersData) => {
    return new Promise(async (resolve) => {
        if (!usersData || usersData.length === 0) {
            resolve();
            return;
        }
        const pictures = await getUsersPicturesAsync(usersData.map((u) => u.id));
        usersData.forEach((user) => {
            const picture = pictures.get(user.id);
            if (!!picture)
                user.picture = picture;
        });
        resolve();
    });
};
const getUsersPicturesAsync = (usersIds) => {
    return new Promise(async (resolve) => {
        if (!teamsFxGraphClient)
            return new Error("TeamxFx graph client is undefined");
        const pictures = new Map();
        const localStoragePictures = getUsersPicturesFromLocalStorage(usersIds);
        localStoragePictures.forEach((value, key) => pictures.set(key, value));
        let usersIdsToFetch = usersIds.filter((id) => !pictures.get(id));
        if (usersIdsToFetch.length === 0)
            return resolve(pictures);
        const batchPicturesRequests = new Array();
        while (usersIdsToFetch.length > 0) {
            // 20 is the limit of steps in a batch request
            const idsToFetch = usersIdsToFetch.slice(0, 20);
            usersIdsToFetch = usersIdsToFetch.slice(20, usersIdsToFetch.length - 1);
            const requests = {
                requests: idsToFetch.map((id) => ({
                    id: id,
                    method: "GET",
                    url: `/users/${id}/photos/48x48/$value`,
                })),
            };
            batchPicturesRequests.push(requests);
        }
        for (const batchRequest of batchPicturesRequests) {
            if (!teamsFxGraphClient)
                return new Error("TeamxFx graph client is undefined");
            const responses = (await teamsFxGraphClient.api("/$batch").post(JSON.stringify(batchRequest)))?.responses;
            if (!responses)
                return new Error("Can't fetch batch request from graph");
            responses.forEach((request) => {
                let base64Image = `data:${request.headers["Content-Type"]};base64,${request.body}`;
                if (!(request.headers["Content-Type"] + "").startsWith("image/"))
                    base64Image = "";
                pictures.set(request.id, base64Image);
            });
        }
        return resolve(pictures);
    });
};
const getUsersPicturesFromLocalStorage = (usersIds) => {
    const pictures = new Map();
    if (!!localStorage) {
        usersIds.forEach((id) => {
            const localData = localStorage.getItem(localStorageUserDataRef + id);
            if (!!localData) {
                const user = JSON.parse(localData);
                if (!!user)
                    pictures.set(id, user.picture ?? "");
            }
        });
    }
    return pictures;
};
const getFilePreviewUrl = async (driveId, itemId) => {
    if (!teamsFxGraphClient)
        return new Error("TeamxFx graph client is undefined");
    const response = await teamsFxGraphClient.api(`/drives/${driveId}/items/${itemId}/microsoft.graph.preview`).post("");
    return response.getUrl;
};
const getFileDownloadUrl = async (driveId, itemId) => {
    if (!teamsFxGraphClient)
        return new Error("TeamxFx graph client is undefined");
    const response = await teamsFxGraphClient.api(`/drives/${driveId}/items/${itemId}`).get();
    return response["@microsoft.graph.downloadUrl"];
};
const getMessageContent = async (messageId) => {
    if (!teamsFxGraphClient) {
        console.error("TeamxFx graph client is undefined");
        return null;
    }
    const response = await teamsFxGraphClient.api(`/me/messages/${messageId}/$value`).get();
    return response;
};
const sendNotification = async (participants, groupId, channelId, title, desc, manifestId, projectId, projectName, webUrl, isTeamsScope = true) => {
    if (!teamsFxGraphClient) {
        console.error("TeamxFx graph client is undefined");
        return null;
    }
    const participantsNotif = participants.map((p) => ({
        upn: p.userPrincipalName,
        Image: p.picture,
        UserAadId: p.id
    }));
    participantsNotif.forEach(async (p) => {
        try {
            if (p.UserAadId !== PARTICIPANT_NO_ID) {
                const scope = isTeamsScope ? `/teams` : `/chats`;
                await teamsFxGraphClient.api(`${scope}/${groupId}/sendActivityNotification`)
                    .post(sendActivityNotificationToRecipients(p, manifestId, !!channelId ? channelId : groupId, projectId, title, desc, projectName, webUrl));
            }
        }
        catch (e) {
            console.error("can't notify this user " + p.upn);
        }
    });
};
const sendActivityNotificationToRecipients = (participants, manifestId, channelId, projectId, title, desc, projectName, webUrl) => {
    const context = encodeURIComponent(JSON.stringify({
        channelId: channelId,
    }));
    const deeplink = `https://teams.microsoft.com/l/entity/${manifestId}/${projectId}?context=${context}`;
    return ({
        topic: {
            source: "text",
            value: projectName,
            webUrl: deeplink,
        },
        activityType: 'systemDefault',
        previewText: {
            content: desc
        },
        recipient: {
            '@odata.type': 'microsoft.graph.aadUserNotificationRecipient',
            userId: participants.UserAadId
        },
        templateParameters: [
            {
                name: 'systemDefaultText',
                value: title
            }
        ]
    });
};
const getJoinedTeam = async () => {
    if (!teamsFxGraphClient) {
        console.error("TeamxFx graph client is undefined");
        return null;
    }
    const res = await teamsFxGraphClient.api(`/me/joinedTeams`).get();
    const teamInfoRef = useRef({});
    res.forEach(t => teamInfoRef.current = { ...teamInfoRef.current, [t.id]: t.displayName });
    const teamInfo = teamInfoRef.current;
    return teamInfo;
};
/**
 * Retrieve personnal file download link
 * @param fileId file id
 */
const getPersonnalFileDownloadLink = async (fileId) => {
    const result = await teamsFxGraphClient?.api("/me/drive/items/" + fileId)
        .get();
    return result["@microsoft.graph.downloadUrl"];
};
const getFileDownloadLink = async (groupId, fileId) => {
    const result = await teamsFxGraphClient?.api("/groups/" + groupId + "/drive/items/" + fileId)
        .get();
    return result["@microsoft.graph.downloadUrl"];
};
const getPersonnalFolderId = async () => {
    const folder = await teamsFxGraphClient?.api("/me/drive/root")
        .select("id")
        .get();
    return folder.id;
};
const getTeamsCurrentChannelFolderId = async (groupId, channelId) => {
    if (!teamsFxGraphClient) {
        return null;
    }
    const folder = await teamsFxGraphClient?.api("/teams/" + groupId + "/channels/" + channelId + "/filesFolder")
        .select("id")
        .get();
    return folder.id;
};
const uploadPersonnalFile = async (fileName, data, folderLocationId) => {
    return await teamsFxGraphClient?.api("/me/drive/items/" + folderLocationId + ":/" + fileName + ":/content")
        .put(data);
};
const uploadFile = async (groupId, fileName, data, folderLocationId) => {
    return await teamsFxGraphClient?.api("/groups/" +
        groupId +
        "/drive/items/" +
        folderLocationId +
        ":/" +
        fileName +
        ":/content")
        .put(data);
};
const getUserGroupFolderData = async (groupId, folderId) => {
    const graphFolderRaw = (await teamsFxGraphClient?.api("/groups/" + groupId + "/drive/items/" + folderId)
        .select("id,name,webUrl,parentReference")
        .expand("children")
        .get());
    return parseFolder(graphFolderRaw);
};
const getUserPersonnalData = async (folderId) => {
    const graphFolderRaw = (await teamsFxGraphClient?.api("/me/drive/items/" + folderId)
        .select("id,name,webUrl,parentReference")
        .expand("children")
        .get());
    return parseFolder(graphFolderRaw);
};
const parseFolder = (rawFolder) => ({
    id: rawFolder.id,
    name: rawFolder.name,
    webUrl: rawFolder.webUrl,
    parentId: rawFolder.parentReference.id,
    content: parseItems(rawFolder.id, rawFolder.children),
});
const parseItems = (parentFolderId, rawItems) => {
    // Sub folders
    const subFolders = new Array();
    // Files
    const files = new Array();
    // Filter items depending of type
    rawItems.forEach((rawItem) => 
    // If item contains key folder, it's a folder else a file
    rawItem.folder
        ? subFolders.push({
            id: rawItem.id,
            content: undefined,
            parentId: parentFolderId,
            webUrl: rawItem.webUrl,
            name: rawItem.name,
        })
        : files.push({
            id: rawItem.id,
            parentId: parentFolderId,
            webUrl: rawItem.webUrl,
            downloadUrl: rawItem["@microsoft.graph.downloadUrl"],
            fullName: rawItem.name,
            name: rawItem.name.indexOf(".") > -1
                ? rawItem.name.substr(0, rawItem.name.lastIndexOf("."))
                : rawItem.name,
            extension: rawItem.name.indexOf(".") > -1
                ? rawItem.name
                    .substr(rawItem.name.lastIndexOf(".") + 1)
                    .toLowerCase()
                : (rawItem.package?.type ?? "").toLowerCase(),
        }));
    return { files, subFolders };
};
const moveFile = async (groupId, fileId, newName, parentFolderId) => {
    return new Promise(async (resolve) => {
        try {
            await teamsFxGraphClient?.api("/groups/" + groupId + "/drive/items/" + fileId)
                .patch({ name: newName, parentReference: { id: parentFolderId } })
                .then(() => resolve(true));
        }
        catch (error) {
            resolve(false);
        }
    });
};
/**
 * Move a personnal file
 * @param fileId file id
 * @param newName new name
 * @param parentFolderId parent folder id
 */
const movePersonnalFile = async (fileId, newName, parentFolderId) => {
    return new Promise(async (resolve) => {
        try {
            await teamsFxGraphClient?.api("/me/drive/items/" + fileId)
                .patch({ name: newName, parentReference: { id: parentFolderId } })
                .then(() => resolve(true));
        }
        catch (error) {
            resolve(false);
        }
    });
};
export const GraphService = {
    searchForUsersAsync,
    areUsersInTeam,
    getUsersAsync,
    getFilePreviewUrl,
    getMessageContent,
    getFileDownloadUrl,
    sendNotification,
    getSitesId,
    getImageFromSharepoint,
    postImageToSharepoint,
    getDriveIdSharepoint,
    getFolderIdSharepoint,
    getUsersPicturesFromLocalStorage,
    getJoinedTeam,
    getPersonnalFileDownloadLink,
    getFileDownloadLink,
    getPersonnalFolderId,
    getTeamsCurrentChannelFolderId,
    getUserPersonnalData,
    getUserGroupFolderData,
    uploadPersonnalFile,
    uploadFile,
    moveFile,
    movePersonnalFile
};
